// double class overload to prevent zol library from overriding tailwindcss styles

.react-select__control.react-select__control {
	@apply rounded-full;

	.react-select__placeholder {
		font-size: unset !important;
	}

	.react-select__dropdown-indicator svg {
		color: #85868f !important;
		color: var(--color-neutral-500) !important;
	}
}

.react-select__menu.react-select__menu {
	.react-select__option:hover {
		background-color: var(--color-neutral-100) !important;
	}
}

.dark {
	.react-select__control.react-select__control {
		background-color: transparent !important;

		.react-select__single-value {
			color: white;
		}
	}

	.disabledSelect .react-select__control.react-select__control {
		background-color: var(--color-neutral-800) !important;
	}

	.react-select__menu.react-select__menu {
		background-color: var(--color-neutral-900) !important;

		.react-select__option {
			color: white;

			&:hover,
			&.react-select__option--is-focused {
				background-color: var(--color-neutral-600) !important;
				color: white !important;
			}

			&.react-select__option--is-selected {
				background-color: var(--color-primary-300) !important;
			}
		}
	}
}
