.container {
	@apply relative flex flex-col flex-wrap;
}

.field {
	box-sizing: border-box;
	width: 100%;
	position: relative;
	font-family: var(--font-primary);
}

.field * {
	box-sizing: border-box;
}

.field:hover textarea {
	background-color: rgba(255, 255, 255, 0.45);
}

.field textarea {
	width: 100%;
	min-width: 100%;
	min-height: 125px;
	position: relative;
	padding: var(--padding-l) var(--padding-xl);
	border-radius: var(--rad-20);
	font-size: var(--paragraph-01);
	font-weight: 400;
	line-height: 16px;
	background-color: transparent;
	color: var(--color-black);
	font-family: var(--font-primary);
	box-sizing: border-box;
	outline: none;
	transition: 0.3s background-color ease-in-out, 0.1s padding ease-in-out;
	-webkit-appearance: none;
	background-color: rgba(255, 255, 255, 0.3);
	border: 2px solid var(--color-neutral-200);
	transition: 0.3s background-color ease-in-out;
	transition: 0.3s border ease-in-out;
}

.field textarea:disabled {
	background: var(--color-neutral-100);
	color: var(--color-neutral-300);
	border-color: var(--color-neutral-200);
	transition-property: border-color;
	transition: 0.3s ease-in-out;
	border: 2px solid var(--color-neutral-100);
}

.field textarea:focus {
	border-color: #2684ff;
}

.field textarea + label {
	position: absolute;
	top: 20px;
	left: 24px;
	font-size: var(--paragraph-03);
	color: var(--color-black);
	font-weight: 400;
	line-height: 16px;
	opacity: 0;
	pointer-events: none;
	transition: 0.1s all ease-in-out;
}

.field textarea:focus + label,
.field textarea:not(:empty) + label {
	top: 2px;
	opacity: 1;
	padding-top: 5px;
	padding-right: 5px;
	background-color: white;
	width: calc(100% - calc(var(--padding-xl) + 8px));
}

.lengthHint {
	font-size: var(--paragraph-02);
	color: var(--color-neutral-500);
	display: inline-block;
	text-align: right;
	transition-property: color, font-weight;
	transition: 0.3s ease-in-out;
}

.error textarea {
	border: 2px solid var(--color-error-500);
}

.error textarea + label {
	color: var(--color-error-500);
}

.errorLengthHint {
	color: var(--color-error-500);
	font-weight: 700;
}

.errorMessage {
	color: var(--color-error-500);
	font-size: var(--paragraph-01);
	margin-top: var(--margin-xs);
	padding-left: var(--padding-s);
	text-align: left;
}

.field textarea::-webkit-input-placeholder,
.field textarea::-moz-placeholder,
.field textarea:-ms-input-placeholder,
.field textarea:-moz-placeholder {
	font-size: var(--paragraph-01);
	color: var(--color-neutral-500);
	opacity: 1;
	transition: 0.1s all ease-in-out;
}

.field textarea:disabled::-webkit-input-placeholder {
	color: var(--color-neutral-300);
}
.field textarea:disabled::-moz-placeholder {
	color: var(--color-neutral-300);
}
.field textarea:disabled:-ms-input-placeholder {
	color: var(--color-neutral-300);
}
.field textarea:disabled:-moz-placeholder {
	color: var(--color-neutral-300);
}

.field textarea:-webkit-autofill,
.field textarea:-webkit-autofill:hover,
.field textarea:-webkit-autofill:focus,
.field textarea:-webkit-autofill:active {
	-webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
	-webkit-transition-delay: 9999s;
}

/*Change text in autofill textbox*/
.field textarea:-webkit-autofill {
	-webkit-text-fill-color: var(--color-black) !important;
}

.field textarea:focus::-webkit-input-placeholder {
	opacity: 0;
}
