.locales-menu-cont {
	display: flex;

	.locales-item {
		cursor: pointer;

		a {
			display: flex;
			align-items: center;
		}

		.flag-icon {
			width: 20px;
			height: fit-content;
		}

		&:not(:last-child) {
			margin-right: 1rem;
		}
	}
}
