.dark {
	* {
		& *[class^="_"] input {
			&:-webkit-autofill {
				-webkit-text-fill-color: white !important;
			}

			&:disabled {
				color: var(--color-neutral-400) !important;
			}
		}

		hr {
			border-color: var(--color-neutral-900);
		}
	}

	/* TEMPORARY RACT RAS LIBRARY OVERLOAD */

	/* outlined primary button*/
	._14eRN._1uk0_._1uG-l {
		border-color: var(--color-primary-400);
		color: white;

		&:hover {
			border-color: var(--color-primary-400);
			background-color: var(--color-primary-400);
			color: white;
		}
	}

	/* transparent primary button */
	._14eRN._2Tl1p {
		color: var(--color-primary-300);
	}

	/* transparent button */

	._14eRN._1YScz {
		color: var(--color-neutral-300);

		svg {
			stroke: var(--color-neutral-300) !important;
		}
	}

	/* Slider */

	._2T5wl {
		._2GgVq {
			background-color: var(--color-neutral-600);
			box-shadow: inset 0 1px 3px 0 var(--color-neutral-800);
		}

		input[type="range"]::-moz-range-thumb,
		input[type="range"]::-ms-thumb,
		input[type="range"]::-webkit-slider-thumb {
			box-shadow: inset 0 1px 3px 0 var(--color-neutral-800);
		}
		input[type="range"]::-webkit-slider-thumb {
			box-shadow: inset 0 1px 3px 0 var(--color-neutral-800);
		}
	}

	.tippy-content > div > div {
		@apply bg-black opacity-100 text-white;
	}
}
