.ActionResult {
	.info {
		@apply text-primary-500;
	}

	.success {
		@apply text-success-500;
	}

	.error {
		@apply text-error-500;
	}

	.warning {
		@apply text-warning-400;
	}
}
