.field {
	padding: 0 21px 0 0;
	border-radius: var(--rad-32);
	transition: 0.3s background-color ease-in-out;
	transition: 0.3s border ease-in-out;
	@apply relative flex items-center box-border w-full h-[52px] bg-white border-2 border-neutral-200;
}

.field * {
	@apply box-border;
}

.field input {
	@apply w-full h-[50px] relative border-none bg-transparent text-black box-border outline-none;

	padding: 0px var(--padding-xl);
	border-radius: var(--rad-32);
	font-size: var(--paragraph-01);
	font-family: var(--font-primary);
	transition: 0.3s background-color ease-in-out, 0.1s padding ease-in-out;
	-webkit-appearance: none;
	@apply appearance-none font-normal leading-4;
}

.field svg {
	@apply cursor-pointer h-[20px];
}

.field input:-webkit-autofill,
.field input:-webkit-autofill:hover,
.field input:-webkit-autofill:focus,
.field input:-webkit-autofill:active {
	-webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
	-webkit-transition-delay: 9999s;
}

.buttonPicto {
	@apply flex border-none bg-transparent relative top-[1px];
	padding: 1px 6px;
}

.buttonPicto {
	@apply text-neutral-700;
}

.buttonPicto:hover {
	@apply text-neutral-500;
}

.buttonPicto:active {
	@apply text-neutral-200;
}

.buttonPicto:focus {
	@apply text-neutral-500;
}

.empty .buttonPicto {
	@apply pointer-events-none;
}

.empty .buttonPicto {
	@apply text-neutral-500;
}

.disabled {
	@apply pointer-events-none cursor-default bg-neutral-50;
}

.disabled input {
	@apply text-neutral-300;
}
