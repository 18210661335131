input[type="checkbox"]:not([data-unstyled]) {
	& {
		@apply relative left-0
        w-4 min-w-[1rem] h-4 p-0
        rounded
        border-none appearance-none
        overflow-hidden
        cursor-pointer;
	}

	/* Aspect de la case */
	&::before,
	&::after {
		content: "";
		@apply absolute left-0 top-0
        w-4 h-4 pt-1
        rounded-sm
        border-none outline outline-2
        text-center text-xs align-middle text-white
        transition-all;
	}

	/* Aspect de la coche */
	&::after {
		content: "✔";
		@apply bg-primary-500
        -left-1 -top-1
        w-6 h-6
        rounded-full;
	}

	/* Aspect de la coche en position indefinie */
	&[data-indeterminate="true"] {
		@apply bg-white outline outline-2 outline-primary-500;
	}
	&[data-indeterminate="true"]:before {
		@apply w-5 h-5;
	}

	&[data-indeterminate="true"]::after {
		@apply w-2 h-2 top-1 left-1 outline-none;
	}

	/* Aspect non cochée */
	&:not(:checked) {
		@apply outline-2 outline-neutral-200 outline;
	}

	&:not(:checked)::after {
		@apply opacity-0 scale-0;
	}

	/* Aspect cochée */
	&:checked::after {
		@apply opacity-100 scale-100;
	}

	/* Disabled checkbox */
	&:disabled::before,
	&:disabled::before {
		@apply shadow-none outline-neutral-300 bg-neutral-100;
	}

	/* Disabled checked */
	&:disabled:checked::after {
		@apply text-neutral-200;
	}

	&:disabled {
		@apply cursor-not-allowed opacity-60 text-neutral-300;
	}

	/* Accessibility focus */
	&:checked:focus::before,
	&:not(:checked):focus::before {
	}
}

.dark {
	input[type="checkbox"]:not([data-unstyled]) {
		/* Aspect non cochée */
		&:not(:checked) {
			@apply outline-2 outline-neutral-300 outline;
		}

		/* Disabled checkbox */
		&:disabled::before,
		&:disabled::before {
			@apply shadow-none outline-red-800 bg-neutral-600;
		}

		&[data-indeterminate="true"] {
			@apply bg-transparent outline outline-2 outline-primary-500;

			&::after {
				@apply text-transparent bg-primary-200;
			}
		}
	}
}
