// target elem with attribute: data-testId ending with "-sortType"
[data-testId$="-sortType"] {
	@apply ml-1;

	& > div {
		width: fit-content;
		min-width: 150px;

		.react-select__menu {
			width: 250px;
			right: 0;
		}
	}
}
