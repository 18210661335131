@import "react-toastify/dist/ReactToastify.min.css";

/* Toastify */

.Toastify__toast-container {
	width: 400px !important;
	font-family: var(--font-primary) !important;
}

.Toastify__toast {
	border-radius: var(--rad-8) !important;
	padding: var(--padding-s) var(--padding-m) !important;
	font-family: var(--font-primary) !important;
	font-size: var(--paragraph-01);
	line-height: var(--paragraph-01);

	&:hover {
		.Toastify__close-button {
			opacity: 1;
		}
	}
}

.Toastify__toast--success {
	background: var(--color-success-500) !important;
	color: white;
}

.Toastify__toast--error {
	background: var(--color-error-500) !important;
	color: white;
}

.Toastify__close-button {
	color: white;
	opacity: 0.7;
}
