.item {
	border-bottom: solid 1px var(--color-neutral-200);
	padding: var(--padding-s) 0 var(--padding-s) var(--padding-l);
}

.item .picto {
	position: absolute;
	top: 14px;
	left: 0;
}

.item .picto svg {
	@apply relative text-black;
}

.primary {
	font-size: var(--paragraph-01);
	line-height: var(--paragraph-01);
	margin-bottom: var(--margin-xs);

	@apply whitespace-nowrap items-center flex flex-row flex-wrap p-0 cursor-pointer bg-transparent border-none outline-none overflow-hidden text-ellipsis;
}

.primary .bold {
	@apply font-semibold;
}

.primary span {
	@apply inline-block whitespace-normal text-left;
	margin-right: var(--margin-m);
}

.primary:hover {
	color: var(--color-neutral-500);
}

.primary:active {
	color: var(--color-black);
}
