.inputMessaging.inputMessaging {
	width: 100%;
	textarea {
		display: flex;
		border: none;
		outline: none;
		resize: none;
		height: 43px;
		min-height: 0;
		padding: 0 10px;
		width: 100%;
		color: #000;

		@apply text-sm;
	}
}
