/* Width and height of the scrollbar */
/* WebKit browsers (Chrome, Safari, and Opera) */
* {
	--scrollbar-size: 7px;
}

::-webkit-scrollbar,
scrollbar {
	width: var(--scrollbar-size);
	height: var(--scrollbar-size);
}

/* Track - the area behind the scrollbar thumb */
/* WebKit browsers (Chrome, Safari, and Opera) */
::-webkit-scrollbar-track,
scrollbar-track {
	background: white;
	border-radius: var(--scrollbar-size);
	margin: 3px;
}

/* Thumb - the draggable scrolling element */
/* WebKit browsers (Chrome, Safari, and Opera) */
::-webkit-scrollbar-thumb,
scrollbar-thumb {
	border-radius: var(--scrollbar-size);
	background: #e0e0e0a1;
	background: var(--color-primary-400);
}

/* Corner - the bottom corner of the scrollbar, where both horizontal and vertical scrollbars meet */
/* WebKit browsers (Chrome, Safari, and Opera) */
::-webkit-scrollbar-corner,
scrollbar-corner {
	background: white;
}
