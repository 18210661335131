.autocompleteInterlocutorsParent {
	position: relative;
	width: fit-content;
}

.autocompleteListWrapper {
	position: absolute;
	top: calc(100% + 8px);
	width: 100%;
	box-shadow: 0 20px 25px 0 rgba(38, 36, 38, 0.15);
	background: white;
	display: flex;
	flex-direction: column;
	z-index: 3;
	border-radius: var(--rad-16);
	max-height: 300px;
	overflow: auto;
	padding: 10px;
	box-sizing: border-box;
}

.autocompleteList {
	display: flex;
	flex-direction: column;
	gap: 8px;
	overflow: auto;
}

.autocompleteOption {
	padding: 6px;
	border-radius: 6px;
	cursor: pointer;
	/* font-size: 16px; */
}

.autocompleteOption:hover {
	background-color: #e7e7e7;
}
