.gaugeNumber {
	font-size: var(--display-02);
	line-height: 52px;
	@apply fill-black font-semibold dark:fill-white;
}

.gaugeText {
	font-size: var(--paragraph-02);
	@apply fill-neutral-500 dark:fill-neutral-100;
	@apply leading-4;
}

.complete,
.active,
.inactive {
	@apply stroke-0;
}

.complete--grey {
	@apply fill-neutral-100;
}

.complete--white {
	@apply fill-white;
}

.complete--primary {
	@apply fill-primary-500;
}

.gaugeLink {
	transition: all 0.4s;
	font-size: var(--paragraph-02);
	@apply cursor-pointer underline underline-offset-4 hover:fill-primary-500 active:fill-primary-300;
}
