// modules
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "toastify/toastify.scss";

// lib
@import "@zolteam/react-ras-library/dist/index.css";

// customs
@import "./fonts.scss";
@import "./select.scss";
@import "./checkbox.scss";
@import "./scrollbar.scss";
@import "./flex.scss";
@import "./inputs.scss";
@import "./darkTheme.scss";

@import "tippy.js/animations/perspective.css";

body {
	margin: 0;
	height: 100svh;
	width: 100%;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: var(--font-primary);
}

#root {
	height: 100%;
	width: 100%;
	accent-color: var(--color-primary-400);
}

.text-balance {
	text-wrap: wrap; /* standard if not supported */
	text-wrap: balance;
}

/* ---------------------------------------- */
/* allow to hide the cursor on input number */
.appearance-textfield {
	appearance: textfield;
	-webkit-appearance: textfield;
	-moz-appearance: textfield;
}
/* Chrome, Safari, Edge, Opera */
input.appearance-textfield::-webkit-outer-spin-button,
input.appearance-textfield::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
/* Firefox */
input.appearance-textfield[type="number"] {
	-moz-appearance: textfield;
}
